export const theme = {
  colors: {
    textColorLight: '#464e57',
    textColor: '#1e262e',
    textColorDark: '#000004',
    white: '#ffffff',
    black: '#000000',
    themeColor: '#192b51',
    primaryLight: '#17439a',
    primary: '#192b51',
    primaryDark: '#0e1f42',
    secondaryLight: '#ffcd4e',
    secondary: '#f39c12',
    secondaryDark: '#bb6e00',
    success: '#28a745',
    info: '#267ccc',
    warning: '#f9c700',
    danger: '#dc3545',
    light: '#f5f5f5',
    dark: '#1e262e',
    background: '#ffffff',
    grayLight: '#eaeaea',
    gray: '#c5c5c5',
    grayDark: '#959595',
    borderColor: '#ededed',
    borderColorTwo: '#f8f8f8',
    borderDash: '#dddddd',
  },
  lineHieghts: {
    text: 1.74,
    heading: 1.41,
  },
  fontFamily: {
    text: 'CerebriSans',
    heading: 'CerebriSans',
    fontAwesomePro: 'Font Awesome 5 Pro',
    fontAwesomeBrand: 'Font Awesome 5 Brands',
  },
  fontSizes: {
    typography: {
      xs: '10px',
      sm: '12px',
      md: '15px',
      lg: '18px',
      xl: '20px',
      xxl: '40px',
    },
    text: '15px',
    h1: '56px',
    h2: '48px',
    h3: '40px',
    h4: '34px',
    h5: '24px',
    h6: '18px',
  },
  fontWeight: {
    regular: 400,
    semiBold: 600,
    bold: 700,
    text: 400,
    heading: 700,
  },
  transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
  spacings: {
    none: '0px',
    xs: '4px',
    sm: '8px',
    md: '12px',
    lg: '16px',
    xl: '20px',
    xxl: '48px',
    xxxl: '64px',
  },
};

const size = {
  xxlarge: '1699px',
  xlarge: '1599px',
  large: '1199px',
  medium: '991px',
  small: '767px',
  xsmall: '575px',
  xxsmall: '480px',
  xxxsmall: '360px',
  xxlargeOnly: '1700px',
  xlargeOnly: '1600px',
  largeOnly: '1200px',
  mediumOnly: '992px',
  smallOnly: '768px',
  xsmallOnly: '576px',
  xxsmallOnly: '481px',
};

export const device = {
  xxlarge: `(max-width: ${size.xxlarge})`,
  xlarge: `(max-width: ${size.xlarge})`,
  large: `(max-width: ${size.large})`,
  medium: `(max-width: ${size.medium})`,
  small: `(max-width: ${size.small})`,
  xsmall: `(max-width: ${size.xsmall})`,
  xxsmall: `(max-width: ${size.xxsmall})`,
  xxxsmall: `(max-width: ${size.xxxsmall})`,
  xxlargeOnly: `(min-width: ${size.xxlargeOnly})`,
  xlargeOnly: `(min-width: ${size.xlargeOnly})`,
  largeOnly: `(min-width: ${size.largeOnly})`,
  mediumOnly: `(min-width: ${size.mediumOnly})`,
  smallOnly: `(min-width: ${size.smallOnly})`,
  xsmallOnly: `(min-width: ${size.xsmallOnly})`,
  xxsmallOnly: `(min-width: ${size.xxsmallOnly})`,
};
