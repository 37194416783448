import React from 'react';

import PropTypes from 'prop-types';
import { IoLogoWhatsapp } from 'react-icons/io';
import { FloatButton } from './styles';

const WhatsappButton = ({ number }) => {
  return (
    <FloatButton href={`https://api.whatsapp.com/send?phone=${number}`} target="_blank">
      <IoLogoWhatsapp />
    </FloatButton>
  );
};

WhatsappButton.propTypes = {
  number: PropTypes.string.isRequired,
};

export default WhatsappButton;
