import { useLocation } from '@reach/router';
import useCookies from './useCookies';

const useSiteLocation = ({ searchOnCookies = false } = {}) => {
  const { pathname } = useLocation();
  const { getCookie } = useCookies();
  const hasRoot = pathname.includes('presencial') || pathname.includes('online');

  const isInPerson =
    ((searchOnCookies || !hasRoot) && getCookie('site-type') === 'presencial') || pathname.includes('presencial');
  const rootPath = isInPerson ? '/presencial' : '/online';

  const regex = /^http/;
  const buildPath = path => (regex.test(path) ? path : `${rootPath}${path}`);

  return { rootPath, isInPerson, buildPath };
};

export default useSiteLocation;
