import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: ${theme.spacings.md};
    background-color: ${theme.colors.dark};
    color: ${theme.colors.white};
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
  `}
`;
