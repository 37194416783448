import Cookies from 'js-cookie';
import { useCallback } from 'react';

export default function useCookies() {
  const setCookie = useCallback((name, value, options) => {
    Cookies.set(name, value, options);
  }, []);

  const removeCookie = useCallback(() => {
    Cookies.remove('name');
  }, []);

  const getCookie = useCallback(name => Cookies.get(name), []);

  const getAllCookies = useCallback(() => Cookies.get(), []);

  return { getCookie, getAllCookies, setCookie, removeCookie };
}
