import React from 'react';
import PropTypes from 'prop-types';
import { LinkWrapper, AnchorTag } from './styles';
import themeConstants from '../../../theme/themeConstants';

const { PRIMARY, SECONDARY, DARK, LIGHT, GRAY, BLACK, WHITE, PRIMARY_LIGHT } = themeConstants.colors;

const Anchor = ({ path, children, allowBlank, ...props }) => {
  const internal = !allowBlank && /^\/(?!\/)/.test(path);
  if (internal) {
    return (
      <LinkWrapper to={path} {...props}>
        {children}
      </LinkWrapper>
    );
  }
  return (
    <AnchorTag href={path} {...props}>
      {children}
    </AnchorTag>
  );
};

Anchor.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([PRIMARY, SECONDARY, DARK, LIGHT, GRAY, BLACK, WHITE, PRIMARY_LIGHT]),
  fontSize: PropTypes.string,
  align: PropTypes.string,
  lineheight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pt: PropTypes.string,
  pb: PropTypes.string,
  mt: PropTypes.string,
  mb: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hover: PropTypes.shape({ color: PropTypes.oneOf([PRIMARY, SECONDARY, DARK, LIGHT, GRAY, BLACK, WHITE]) }),
};

Anchor.defaultProps = {
  color: BLACK,
  hover: { color: PRIMARY },
};

export default Anchor;
