const colors = {
  PRIMARY: 'primary',
  PRIMARY_LIGHT: 'primaryLight',
  SECONDARY: 'secondary',
  LIGHT: 'light',
  DARK: 'dark',
  WHITE: 'white',
  BLACK: 'black',
  GRAY: 'gray',
};

const fontWeight = {
  REGULAR: 'regular',
  SEMIBOLD: 'semiBold',
  BOLD: 'bold',
};

const sizes = {
  NONE: 'none',
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
};

export default { colors, fontWeight, sizes };
