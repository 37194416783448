import React, { useState, useEffect } from 'react';
import { MdArrowUpward } from 'react-icons/md';
import { ScrollTopButton } from './styles';

const ScrollToTop = () => {
  const [stick, setStick] = useState(false);
  const onClickHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    let position = window.pageYOffset;

    const scrollHandler = () => {
      const scrollPos = window.pageYOffset;
      if (scrollPos < 200) {
        setStick(false);
      } else if (scrollPos < position) {
        setStick(true);
      } else {
        setStick(false);
      }
      position = scrollPos;
    };

    window.addEventListener('scroll', () => {
      scrollHandler();
    });
    return () => {
      window.removeEventListener('scroll', () => {
        scrollHandler();
      });
    };
  }, [stick]);

  return (
    <ScrollTopButton type="button" onClick={onClickHandler} isStick={stick}>
      <MdArrowUpward className="icon arrow-up" />
    </ScrollTopButton>
  );
};

export default ScrollToTop;
